.SmartTribune {
  min-height: 100vh;
}

#st-faq-root {
  display: none !important;

  #st-faq > & {
    display: block !important;
  }
}
