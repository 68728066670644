.Spinner {
  align-items: center;
  display: flex;
  flex-grow: 1;
  height: 100%;
  justify-content: center;
  width: 100%;

  &::before {
    @include pseudo-icon(
      escape-svg(
        url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' fill='none' focusable='false' stroke='none' viewBox='0 0 24 24'><path d='M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z' fill='currentColor' /></svg>")
      ),
      3rem,
      3rem
    );
    animation: Spinner 750ms linear infinite;

    @keyframes Spinner {
      to {
        transform: rotate(360deg);
      }
    }
  }
}
