@import "./styles/abstracts";

.AuthenticationApp {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 120rem;
  min-height: 100vh;
  padding: $Content-gutter;

  > :not(:last-child) {
    margin-bottom: $Content-gutter;
  }

  &-ad {
    display: none;

    @include for-desktop() {
      display: block;
      flex-grow: 1;
      position: relative;
    }
  }

  &-assistanceHotlinePush {
    flex-grow: 1;
  }

  &-assistanceOnlinePush {
    bottom: $Content-gutter;
    position: absolute;
    right: 0;
  }

  &-companyNav {
    width: 100%;

    @include for-desktop() {
      width: calc(50% - 82px);
    }
  }

  &-footer {
    > div {
      > :not(:last-child) {
        margin-bottom: $Content-gutter;
      }
    }
  }

  &-header {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: $Content-gutter;
  }

  &-main {
    background: #fff;
    box-shadow: 0 0.5rem 0.5rem rgba(#979797, 0.25);
    display: flex;
    flex-grow: 1;
  }

  &-more {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: $Content-gutter;

    @include for-desktop() {
      flex-direction: row;
    }
  }

  &-routes {
    display: flex;
    flex-direction: column;
    padding: $Content-gutter;
    width: 100%;

    @include for-desktop() {
      width: 35rem;
    }
  }
}
