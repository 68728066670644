.Table {
  border-collapse: collapse;
  width: 100%;

  > tbody {
    > tr {
      > td {
        padding: $Content-gutter;
      }

      &:not(:last-child) {
        border-bottom: $Separator;
      }
    }
  }

  > thead {
    background: #e3e4ec;

    > tr {
      > th {
        padding: $Content-gutter;
        text-align: left;
        text-transform: uppercase;
        white-space: nowrap;

        &:last-child {
          width: 1px;
        }

        &[data-fluid="true"] {
          width: "100%";
        }

        &[data-nowrap="true"] {
          white-space: nowrap;
        }
      }
    }
  }
}
